@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

/* people png Animaiton CSS */
@keyframes slowMoveX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slowMoveY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  50% {
    transform: translate(-4px, -8px);
  }
}

.slow-motionX-box {
  animation: slowMoveX 3s ease-in-out infinite;
}
.slow-motionY-box {
  animation: slowMoveY 4s ease-in-out infinite;
}
.bounce-motion {
  animation: bounce 2s ease-in infinite;
}

/* 설명 페이지 */
.desc-bg {
  background-image: url("../public/img/descBg.jpg");
}
/* 실시간 QnA 페이지 */
.qna-bg {
  background-image: url("../public/img/qnaBg.jpg");
}

body {
  overflow: hidden !important;
  height: 100%;
}
#App-first-container {
  overflow: hidden !important;
}

@media (orientation: portrait) {
  /* 기본 설정 */
  html {
    transform: rotate(-90deg);
    transform-origin: top left;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100dvh;
    height: 100dvw;
    /* overflow: hidden; */
    overflow-y: auto;
  }

  .App {
    width: 100dvh;
    height: 100dvw;
    position: relative;
    /* overflow: hidden; */
    overflow-y: auto;
    width: 100%;
  }
  .overflowHidden {
    overflow: hidden !important;
  }
  .App-header {
    min-height: 100%;
  }
  /* 챗봇 */
  .rsc > div {
    width: 350px !important;
  }

  /* background img 설정 */
  .bg-in {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
}

@media (orientation: landscape) {
  /* Landscape 모드일 때 적용할 CSS */
  html {
    width: 100dvw;
    height: 100dvh;
    /* overflow: hidden; */
    overflow-y: auto;
  }

  .App {
    width: 100dvw;
    height: 100dvh;
    position: relative;
    /* overflow: hidden; */
    overflow-y: auto;
    width: 100%;
  }
  /* html {
    overflow: hidden;
  } */

  /* background img 설정 */
  .bg-in {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  #qna_teacher {
    left: 17%;
  }
}

/* pc ver. */
@media (min-width: 1266px) {
  /* main */
  #mainImg {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
  }

  /* background img 설정 */
  .bg-in {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
  }

  /* .video-Container {
    margin: 0 5em;
  }
  .video-Container iframe {
    max-height: 460px;
    min-height: 360px;
  } */
}
